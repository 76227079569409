<template>
  <div class="modal-message">
    <div class="message-content col-md-6">
      <div v-if="!html">{{ mess }}</div>
      <div v-else v-html="mess"></div>
      <div>
        <button @click="hide" class="btn btn-light btn-sm shadow-none">
          {{ buttonCancelText ? buttonCancelText : "понятно" }}
        </button>
        <button
          v-if="butonSave"
          @click="ok"
          class="btn btn-success btn-sm shadow-none"
        >
          {{ buttonOkText ? buttonOkText : "сохранить" }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ModalMessage",
  props: ["message", "butonSave", "buttonOkText", "buttonCancelText"],
  data: () => ({
    mess: "",
    html: false
  }),
  methods: {
    hide() {
      this.$emit("hideMessage");
    },
    ok() {
      this.$emit("save");
    },
    transform() {
      if (this.mess.indexOf("|") > 0) {
        const n = this.mess.split("|");
        this.mess = n.map(item => `<div>${item}</div>`);
        this.mess = this.mess.join("");
        this.html = true;
      }
    }
  },
  mounted() {
    this.mess = this.message;
    this.transform();
  }
};
</script>
<style lang="scss" scoped>
.modal-message {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background: rgba($color: white, $alpha: 0.5);
}
.message-content {
  text-align: center;
  padding: 20px;
  background: #0062cc;
  color: white;
  border-radius: 3px;
}
button {
  margin: 20px 5px;
}
</style>
