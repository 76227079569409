<template>
  <div>
    <div>
      <div class="row col-md-12 period" v-if="$route.query.check === 'true'">
        <div
          class="form-control form-control-sm shadow-none text-center col-md-3 mt5"
          v-if="!viewAllRepair"
        >
          создание отчета за
        </div>
        <select
          class="form-control form-control-sm shadow-none col-md-2 mt5"
          v-model="REPORTSDATE.mountOrQuarter"
          @change="changeDate"
          v-if="!viewAllRepair"
        >
          <option value="month">месяц</option>
          <option value="quarter">квартал</option>
        </select>
        <select
          class="form-control form-control-sm shadow-none col-md-2 mt5"
          v-if="REPORTSDATE.mountOrQuarter === 'month' && !viewAllRepair"
          v-model="REPORTSDATE.month"
          @change="changeDate"
        >
          <option value="1">январь</option>
          <option value="2">февраль</option>
          <option value="3">март</option>
          <option value="4">апрель</option>
          <option value="5">май</option>
          <option value="6">июнь</option>
          <option value="7">июль</option>
          <option value="8">август</option>
          <option value="9">сентябрь</option>
          <option value="10">октябрь</option>
          <option value="11">ноябрь</option>
          <option value="12">декабрь</option>
        </select>
        <select
          class="form-control form-control-sm shadow-none text-center col-md-1 mt5"
          v-if="REPORTSDATE.mountOrQuarter === 'quarter' && !viewAllRepair"
          v-model="REPORTSDATE.quarter"
          @change="changeDate"
        >
          <option value="1" class="text-center">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
        </select>
        <select
          class="form-control form-control-sm shadow-none col-md-2 mt5"
          v-model="REPORTSDATE.year2"
          @change="changeDate"
          v-if="!viewAllRepair && years.length"
        >
          <option
            v-for="(item, idx) in years"
            :key="idx + 'ye'"
            :value="item"
            >{{ item }}</option
          >
        </select>
        <div
          class="form-control form-control-sm shadow-none text-center create col-md-2 mt5"
          v-if="
            !viewAllRepair && repairList && repairList.length > 0 && buttonSend
          "
          @click="createReport"
        >
          создать
        </div>
      </div>
      <div class="row col-md-12 period" v-if="$route.query.check === 'true'">
        <div
          class="form-control form-control-sm shadow-none col-md-4 text-center change"
          :class="!viewAllRepair ? '' : 'click'"
          @click="create"
        >
          показать все доступные ремонты
        </div>
        <select
          v-if="USER_INFO.status === 'admin'"
          class="form-control form-control-sm shadow-none col-md-3"
          v-model="REPORTSDATE.user"
          @change="changeDate"
        >
          <option value="all">все</option>
          <option
            v-for="(item, idx) in users_list"
            :key="idx + 'usersLIst'"
            :value="item.id_user"
            >{{ `${idx + 1} ${item.user} | ${item.repairs_count}шт.` }}</option
          >
        </select>
      </div>
    </div>
    <div
      class="period text-center"
      v-if="
        !buttonSend &&
          repairList &&
          repairList.length > 0 &&
          $route.query.check === 'true' &&
          !viewAllRepair
      "
    >
      нельзя создавать отчет пока не закончился выбранный период!!!
    </div>
    <div
      class="text-center table-header"
      v-if="$route.query.check === 'true' && !viewAllRepair"
    >
      ремонты отбираются по дате окончания ремонта
    </div>
    <div
      v-if="$route.query.check === 'false'"
      class="alert alert-warning"
      role="alert"
    >
      мы постараемся как можно быстрее проставить стоимость работ и Вы сможете
      включить ремонты в отчет
    </div>
    <div class="table-wrapper">
      <table class="table table-sm" v-if="repairList && repairList.length">
        <tbody v-if="repairList">
          <tr class="no-cursor">
            <th></th>
            <th>СЦ</th>
            <th>№</th>
            <th>№ СЦ</th>
            <th>наименование</th>
            <th>вид ремонта</th>
            <th>работы</th>
            <th>категория</th>
            <th v-if="$route.query.check === 'true'">зч</th>
            <th v-if="$route.query.check === 'true'">работы</th>
            <th v-if="$route.query.check === 'true'">сумма</th>
            <th>дата принятия в ремонт</th>
            <th>дата окончания ремонта</th>
          </tr>
          <tr v-if="$route.query.check === 'true'" class="no-cursor">
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="bold">
              {{ number(summaZap) }}
            </td>
            <td class="bold">
              {{ number(summaRabot) }}
            </td>
            <td class="bold">
              {{ number(summ) }}
            </td>
            <td></td>
            <td></td>
          </tr>
          <tr
            v-for="(item, idx) in repairList"
            :key="idx"
            @click="goRepair(item.remont_id)"
          >
            <td>{{ idx + 1 }}</td>
            <td>{{ item.user }}</td>
            <td>{{ item.repair_number }}</td>
            <td>{{ item.receiptNumber }}</td>
            <td>{{ item.name_instr }}</td>
            <td>{{ repairType(item.typeOfRepair) }}</td>
            <td>{{ item.workCarried }}</td>
            <td>{{ item.repair_category }}</td>
            <td v-if="$route.query.check === 'true'">
              {{ number(item.summzap) }}
            </td>
            <td v-if="$route.query.check === 'true'">
              {{ item.repair_price }}
            </td>
            <td v-if="$route.query.check === 'true'">
              {{ number(item.repair_price + item.summzap) }}
            </td>
            <td>{{ item.dateRepair }}</td>
            <td>{{ item.repair_is_over_data }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      class="input-group col-md-12 period"
      v-if="
        repairList &&
          !repairList.length &&
          $route.query.check === 'true' &&
          !viewAllRepair
      "
    >
      <div class="form-control form-control-sm shadow-none text-center">
        за выбранный период ремонты отсутствуют
      </div>
    </div>
    <div
      class="input-group col-md-12 period"
      v-if="repairList && !repairList.length && $route.query.check === 'false'"
    >
      <div class="form-control form-control-sm shadow-none">
        ремонты отсутствуют
      </div>
    </div>
    <ModalMessage
      v-if="visibleMessage"
      @hideMessage="cancelCreateReport"
      @save="getDataRepoort"
      :butonSave="true"
      buttonCancelText="отмена"
      buttonOkText="создать"
      :message="
        `период отчета - ${
          REPORTSDATE.mountOrQuarter === 'quarter'
            ? `${REPORTSDATE.quarter} квартал`
            : monthru
        } ${REPORTSDATE.year2} года | 
        количество ремонтов - ${repairList.length} шт.| 
        стоимость запчастей - ${number(summaZap)} р. |
        стоимость работ - ${number(summaRabot)} р. |
        запчасти + работы - ${number(summaRabot + summaZap)} р. |
        если все верно нажмите 'создать'`
      "
    />
    <Loader v-if="loading" />
  </div>
</template>
<script>
import { HTTPGET, HTTPGETNOLOADER, HTTPPOST } from "@/api";
import { mapActions, mapGetters, mapMutations } from "vuex";
import ModalMessage from "@/components/ModalMessage.vue";
import { number_format } from "@/components/functions/number_format.js";
import Loader from "@/components/Loader.vue";
export default {
  name: "RepairCheckList",
  components: {
    ModalMessage,
    Loader
  },
  computed: {
    ...mapGetters(["USER_INFO", "REPORTSDATE"]),
    summaRabot: function() {
      let summ = 0;
      this.repairList.map(item => (summ += item.repair_price));
      return summ;
    },
    summaZap: function() {
      let summ = 0;
      this.repairList.map(item => (summ += item.summzap));
      return summ;
    },
    summ: function() {
      let summ = 0;
      this.repairList.map(
        item => (summ += item.summzap) + (summ += item.repair_price)
      );
      return summ;
    },
    monthru: function() {
      const m = [
        "январь",
        "февраль",
        "март",
        "апрель",
        "май",
        "июнь",
        "июль",
        "август",
        "сентябрь",
        "октябрь",
        "ноябрь",
        "декабрь"
      ];
      return m[this.REPORTSDATE.month - 1];
    },
    repairsIdList: function() {
      let list = {};
      this.repairList.map((item, i) => (list[i] = item.remont_id));
      return list;
    }
  },
  watch: {
    $route() {
      this.get_data(HTTPGET);
    }
  },
  data: () => ({
    interval: null,
    repairList: [],
    url: "/reports/get_repair_check_list.php",
    viewAllRepair: false,
    visibleMessage: false,
    buttonSend: false,
    users_list: [],
    loading: true,
    years: []
  }),
  mounted() {
    this.ADDPATH("ремонты на оценке");
    this.interval = setInterval(() => {
      this.get_data(HTTPGETNOLOADER);
    }, 10000);
    this.currentQuarter();
    this.currentMonth();
    this.curentYear();
    this.get_data(HTTPGET);
    this.buttonSendVisible();
  },
  methods: {
    ...mapActions(["ADDPATH"]),
    ...mapMutations(["set_quarter", "set_year2", "set_month"]),
    get_data(method, loader = false) {
      if (loader) {
        this.loading = true;
      }
      method({
        url: this.url,
        params: {
          check: this.$route.query.check,
          mountOrQuarter: this.REPORTSDATE.mountOrQuarter,
          month: this.REPORTSDATE.month,
          quarter: this.REPORTSDATE.quarter,
          year: this.REPORTSDATE.year2,
          user: this.REPORTSDATE.user,
          viewAllRepair: this.viewAllRepair
        }
      }).then(response => {
        this.repairList = response.data.repairs;
        this.users_list = response.data.users_list;
        this.years = response.data.repairs_years;
        this.loading = false;
      });
    },
    repairType(type) {
      if (type === "repair") {
        return "ремонт";
      }
      if (type === "noRepair") {
        return "акт";
      }
      if (type === "diagnostiks") {
        return "диагностика или РБЗЧ";
      }
    },
    goRepair(id) {
      this.$router.push(`/repairs/repair/${id}?path=check`);
    },
    changeDate() {
      this.buttonSendVisible();
      this.viewAllRepair = false;
      this.get_data(HTTPGET, true);
    },
    currentQuarter() {
      const today = new Date();
      const quarter = Math.floor((today.getMonth() + 3) / 3);
      if (quarter < 2) {
        this.set_quarter(4);
        this.set_year2(this.REPORTSDATE.year2 - 1);
      } else {
        this.set_quarter(this.REPORTSDATE.quarter - 1);
      }
    },
    buttonSendVisible() {
      this.buttonSend = false;
      if (this.REPORTSDATE.mountOrQuarter === "quarter") {
        const today = new Date();
        const quarter = Math.floor((today.getMonth() + 3) / 3);
        if (
          Number(this.REPORTSDATE.year2) === new Date().getFullYear() &&
          this.REPORTSDATE.quarter < quarter
        ) {
          this.buttonSend = true;
        }
      }
      if (this.REPORTSDATE.mountOrQuarter === "month") {
        const month = new Date().getMonth() + 1;
        if (
          Number(this.REPORTSDATE.year2) === new Date().getFullYear() &&
          parseInt(this.REPORTSDATE.month) < month
        ) {
          this.buttonSend = true;
        }
      }
      const year = new Date().getFullYear();
      if (Number(this.REPORTSDATE.year2) < year) {
        this.buttonSend = true;
      }
    },
    currentMonth() {
      if (new Date().getMonth() + 1 < 2) {
        this.set_month(12);
      }
    },
    curentYear() {
      const mont = new Date().getMonth();
      if (mont === 0) {
        this.set_year2(this.REPORTSDATE.year2 - 1);
      }
    },
    create() {
      this.viewAllRepair = !this.viewAllRepair;
      this.get_data(HTTPGET);
    },
    getDataRepoort() {
      this.visibleMessage = false;
      HTTPPOST({
        url: "reports/create_report.php",
        data: {
          data: JSON.stringify(this.repairsIdList),
          mountOrQuarter: this.REPORTSDATE.mountOrQuarter,
          month: this.REPORTSDATE.month,
          quarter: this.REPORTSDATE.quarter,
          year: this.REPORTSDATE.year2,
          summaRabot: this.summaRabot,
          summaZap: this.summaZap
        }
      }).then(response =>
        this.$router.push(`/reports/report/${response.data.report_id}`)
      );
    },
    cancelCreateReport() {
      this.visibleMessage = false;
    },
    createReport() {
      this.visibleMessage = true;
    },
    number(s) {
      return number_format(s, 2, ",", " ");
    }
  },
  destroyed() {
    clearInterval(this.interval);
  }
};
</script>
<style lang="scss" scoped>
table {
  font-size: 0.7em;
  width: 100%;
}
td,
th {
  vertical-align: middle;
}
.w50 {
  width: 70%;
}
tr {
  cursor: pointer;
}
.period {
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 5px;
}
.create {
  cursor: pointer;
}
.create:hover {
  background: #007bff;
  color: #ffffff;
  border-color: #007bff;
}
.change:hover {
  cursor: pointer;
  border-color: #ff0000;
}
.click {
  background: #ff0000;
  border-color: #ff0000;
  color: #ffffff;
}
.table-wrapper {
  overflow: auto;
}
.bold {
  font-weight: bold;
  white-space: nowrap;
}
.table-header {
  font-size: 0.7em;
  font-weight: bold;
}
.period {
  font-size: 0.8em;
  font-weight: bold;
  color: #ff0000;
}
.no-cursor {
  cursor: default;
}
.mt5 {
  margin-top: 5px;
}
</style>
